<template>
  <div class="customer-filter">
    <el-form size="small" ref="searchForm" :model="searchForm" :inline="true">
       <el-form-item label="测试项目">
        <enumSelector
            ref="enumSelector"
            :options="$config.kneeHurtType"
            :clearable="false"
            size="small"
            v-model="searchForm.type"
          />
      </el-form-item>
      <el-form-item label="关键字">
        <el-input
          v-model="searchForm.search"
         placeholder="请输入关键词"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item>
        <slot></slot>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import enumSelector from "@/components/enumSelector";
export default {
  components: {
    enumSelector,
  },
  props: {
  },
  data() {
    return {
      searchForm: {
        search: "",
         type:"",
      },
    };
  },
  methods: {
    getfilter() {
      let filters = {
        search: this.searchForm.search,
        type:this.searchForm.type,
      };
      return filters;
    },
  },
  mounted() {},
};
</script>
<style lang='scss' scoped>

/deep/.el-form-item
{
  margin-bottom: 5px;
}
</style>